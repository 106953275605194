// Imports
import Vue from "vue";
import Vuex from "vuex";

// Vuex Modules
import AppModules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,

  modules: {
    ...AppModules,
  },
  state: {
    loadingGlobal: false,
  },
  getters: {
    getLoadingGlobal: (state) => state.loadingGlobal,
  },
  mutations: {
    // Set Global Loading Mutation
    SET_LOADING_GLOBAL(state, value) {
      state.loadingGlobal = value;
    },
  },
  actions: {
    setLoadingGlobal({ commit }, value) {
      commit("SET_LOADING_GLOBAL", value);
    },
  },
});
