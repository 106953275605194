export const ItemsModule = {
  namespaced: true,
  state: {
    currentItem: {},
  },
  getters: {
    // Get Current Item
    getCurrentItem: (state) => state.currentItem,
  },
  mutations: {
    // Set Current Item Mutation
    SET_CURRENT_ITEM(state, item) {
      state.currentItem = item;
    },
  },
  actions: {
    // Set Current Item
    setCurrentItem({ commit }, item) {
      commit("SET_CURRENT_ITEM", item);
    },
  },
};
