<template>
  <div id="app">
    <!-- Main Content -->
    <div v-if="!getLoadingGlobal" class="main-content">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <!-- Loading Overlay -->
    <LoadingOverlay v-if="getLoadingGlobal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    LoadingOverlay: () => import("@/components/globals/LoadingOverlay"),
  },
  computed: {
    ...mapGetters(["getLoadingGlobal"]),
  },
  mounted() {
    // This is just a test for the loading global data
    this.setLoadingGlobal(true);
    setTimeout(() => {
      this.setLoadingGlobal(false);
    }, 2000);
  },
  methods: {
    ...mapActions(["setLoadingGlobal"]),
  },
};
</script>

<style lang="sass">

@import'~bootstrap/dist/css/bootstrap.css'

@import './assets/scss/custom.scss'

html, body
  height: 100%
  margin: 0

#app
  height: 100%
  display: flex
  flex-direction: column
  @apply bg-ri_white

.main-content
  flex: 1
  overflow: auto
  font-family: "Helvetica Neue", sans-serif

// Remove Arrows on Inputs that are Numbers
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

// Transition
.fade-enter-active, .fade-leave-active
  transition: opacity .3s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
