// Imports
import Vue from "vue";
import VueRouter from "vue-router";
import AppRoutes from "./routes";

// Add Plugin
Vue.use(VueRouter);

// Routes
const routes = [...AppRoutes];

// Create Router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
