// Items Views
const ItemsDetailsView = () => import('@/views/items/ItemsDetailsView')

export default [
  {
    path: '/items/:id/:pin',
    name: 'ItemsDetailsView',
    component: ItemsDetailsView
  },
  {
    path: '/items/:id',
    name: 'ItemsDetailsView',
    component: ItemsDetailsView
  }
]
